<template>
  <div class="wapper">
    <commonHeader :backURL="backURL" />
    <div class="payment-result">
      <!--支付成功-->
      <template v-if="order_status === 1 || order_status === 2 || order_status === 4">
        <img class="image" :src="require('@/assets/images/payment/result_success.png')" />
        <p class="title">支付成功 !</p>
        <p class="subtitle">
          快分享给小伙伴加入拼团吧~
        </p>

        <van-button v-if="qqLink" round type="info" color="#FF6A4D" class="button" @click="clickClassButton(qqLink)"
          style="margin-bottom: 0.5rem">加入班级群
        </van-button>
        <br>
        <van-button round plain type="info" color="#FF6A4D" class="button" @click="clickButton">查看订单详情</van-button>
      </template>

      <!--等待支付，显示支付失败页面-->
      <template v-if="order_status === 0 && showFail">
        <img class="image" :src="require('@/assets/images/payment/result_fail.png')" />
        <p class="title">支付失败</p>
        <van-button round type="info" color="#FF6A4D" class="button" @click="clickButton">返回订单
        </van-button>
      </template>

      <!--支付等待中-->
      <template v-if="order_status === 0 && !showFail">
        <img class="image" :src="require('@/assets/images/payment/result_wait.png')" />
        <p class="title">等待支付...</p>
        <p class="subtitle">若您完成支付，但页面长时间未跳转，请<a :href="dialogueURL" class="link">联系客服</a></p>
      </template>

      <p class="subtitle fixed" v-if="order_status !== 0 || (order_status === 0 && showFail)">
        如您有任何疑问，请<a :href="dialogueURL" class="link">联系客服</a>
      </p>
    </div>
  </div>
</template>

<script>
import { apiQueryPaymentResult } from '@/api/order'
import commonMixins from "@/mixins/common";

export default {
  mixins: [commonMixins],
  data () {
    return {
      no: '', //订单信息
      qqLink: '',//客服链接
      backURL: '',
      order_status: '',
      dialogueURL: '',
      showFail: false,
      count: 0,
    }
  },
  mounted () {
    this.no = this.$route.query.no
    this.checkOrder()
    this.$store.dispatch('getDialogueURL').then((dialogueURL) => {
      this.dialogueURL = dialogueURL
    })
    this.$util.setShare()
  },
  methods: {
    // 检查订单
    checkOrder () {
      this.queryPaymentResult()

      setInterval(() => {
        // 订单失败，且超过5分钟
        if (this.count > 150) {
          this.showFail = true
          return
        }
        // 订单非失败状态，停止计时器
        if (this.order_status === 1) {
          return;
        }

        this.count = this.count + 1
        this.queryPaymentResult()

      }, 2000)
    },
    // 检查订单信息
    queryPaymentResult () {
      let no = this.no
      return apiQueryPaymentResult({ no }).then((res) => {
        // 0:未支付 1:已支付 2:退款中 3:退款成功
        let resData = res?.data
        this.order_status = resData.order_status
        this.qqLink = resData?.qq_info?.link
        if (resData.url) {
          this.dialogueURL = resData.url
        }
        //支付成功调用
        if (resData.order_status !== 0) {
          this.backURL = this.getGoodsDetailURLFn(resData)
          this.clickButton()
        }


      })
    },

    getGoodsDetailURLFn (options) {
      // detail_info
      let url = '';
      if (options.order_status === 0) {
        url = '/wap/goods/detail?id=' + options.id
        if (options.rid) {
          url += '&rid=' + options.rid
        }
      } else {
        // 支付成功跳转订单详情界面
        url = `/wap/order/detail?gid=${options.detail_info.gid}&id=${options.detail_info.id}&rid=${options.detail_info.rid}&classmodal=true`
      }


      return window.location.origin + url
    },

    clickButton () {
      this.$util.locationHref(this.backURL)
    },
    clickClassButton (url) {
      window.open(url)
    }
  },
}
</script>

<style scoped lang="scss">
.wapper {
  position: relative;
  min-height: 100vh;
  background-color: #fff;
}

.payment-result {
  text-align: center;

  .image {
    @include margin_center();
    margin-top: 1.8rem;
    width: 2.88rem;
    height: 2.88rem;
    display: block;
    margin-bottom: 0.53rem;
  }

  .title {
    font-size: 0.53rem;
    font-weight: bold;
    line-height: 0.53rem;
    color: #22242e;
    margin-bottom: 0.53rem;
  }

  .subtitle {
    font-size: 0.35rem;
    color: #919499;
    margin-bottom: 1rem;

    .link {
      color: #448aff;
    }

    &.fixed {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      bottom: 1rem;
      margin-bottom: 0;
    }
  }

  .button {
    width: 3.73rem;
    height: 1rem;
    font-size: 0.37rem;
  }
}
</style>
